$light-bg: white;
$dark-bg: #301C3F;

$primary-color: #026170;
$secondary-color: #4060D1;
$accent-color: #2CC294;
$warn-color: #F2404A;
$green-1: #019294;
$green-2: #2CC294;
$yellow: #E2D008;

$info-color-dark: #1a1e29;
$info-color-light: #5E5F60;

$text-color-dark: #301C3F;
$text-color-light: #cccccc;
$title-color: #301C3F;
$navigation-item-color: #5E5F60;
$navigation-item-hover-bg: #F8F8FC;
$transition: all .15s ease-in-out;

// Club status colors
$club-created: $yellow;
$club-actvie: $green-2;
$club-declined: $warn-color;
$club-suspended: $warn-color;
$club-deleted: $warn-color;


@mixin text-elipsis() {
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
}

@function add-color-shade($base-color, $shade-color) {
   $red: red($base-color) + red($shade-color);
   $green: green($base-color) + green($shade-color);
   $blue: blue($base-color) + blue($shade-color);

   @return rgb($red, $green, $blue);
}

@function sub-color-shade($base-color, $shade-color) {
   $red: red($base-color) - red($shade-color);
   $green: green($base-color) - green($shade-color);
   $blue: blue($base-color) - blue($shade-color);

   @return rgb($red, $green, $blue);
}