@import "./custom-theme.scss";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "./variables.scss";
@import 'bdd-flex/src/bdd-flex.scss';
@import "./layout.scss";

// customization

@layer reset, primeng;

@layer reset {
   button {
      &:focus {
         box-shadow: none;
      }
   }
}


*,
*::after,
*::before {
   box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4 {
   margin: 0;
}

p {
   color: $text-color-dark;
}

html,
body {
   padding: 0;
   margin: 0;
   min-height: 100vh;
}

body {
   overscroll-behavior: none;
}

//! Typography classes
.title {
   font-size: 32px;
   color: $title-color;
}

.subtitle {
   font-size: 16px;
   color: $title-color;
   font-weight: 700;
}

.font-size-12 {
   font-size: 12px;
}

.font-size-14 {
   font-size: 14px;
}

.font-size-16 {
   font-size: 16px;
}

.font-size-18 {
   font-size: 18px;
}

.font-size-20 {
   font-size: 20px;
}

.font-size-24 {
   font-size: 24px;
}

.font-w-300 {
   font-weight: 300;
}

.font-w-400 {
   font-weight: 400;
}

.font-w-500 {
   font-weight: 500;
}

.font-w-600 {
   font-weight: 600;
}

.font-w-700 {
   font-weight: 700;
}

// !!!NGPRIME OVERRIDES
*[required] {
   content: ' *';
}

p-floatLabel:has(*[required]:first-child) label::after {
   content: "  *";
}

p-floatLabel:has(*[required="false"]:first-child) label::after {
   content: "";
}

input {
   width: 100%;
}

.inputSwitch {
   display: flex;
   gap: 8px;

   label {
      user-select: none;
      cursor: pointer;
      margin-bottom: 4px;
   }
}

// Font and color classes
.primary-color {
   color: $primary-color;
}

.secondary-color {
   color: $secondary-color;
}

.accent-color {
   color: $accent-color;
}

.info-color {
   color: $info-color-light;
}

.info-color-dark {
   color: $info-color-dark;
}

// End of font and color classes

// !Component style classes
.w-100 {
   width: 100%;
}

.no-wrap {
   white-space: nowrap;
}

.ellipsis {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.card-component {
   background-color: $light-bg;
   border-radius: 6px;
   padding: 24px;

   .title {
      font-size: 18px;
      color: $info-color-dark;
      font-weight: 500;
      padding-bottom: 4px;
      border-bottom: 1px solid $info-color-light;
      margin-bottom: 16px;
   }
}

.deleted-list-item {
   background-color: change-color($color: $warn-color, $alpha: .2);
}

//Global classes and components
.form-hint {
   padding-inline: 8px;
   font-size: 12px;
   color: $warn-color;
}

.inputSwitch {
   display: flex;
   gap: 8px;

   label {
      user-select: none;
      cursor: pointer;
      margin-bottom: 4px;
   }
}

// Tag component
.tag {
   border-radius: 6px;
   padding: 4px 8px;
   width: fit-content;
   font-size: 14px;
   font-weight: 500;
}

.tag.primary {
   background-color: rgba($accent-color, 0.1);
   color: $accent-color;
}

.tag.warn {
   background-color: rgba($warn-color, 0.1);
   color: $warn-color;
}

.tag.pending {
   background-color: rgba($yellow, 0.1);
   color: $yellow;
}

.tag.info {
   background-color: rgba($secondary-color, 0.1);
   color: $secondary-color;
}

// Menu-item
div.menu-item {
   padding: 12px 20px;
   cursor: pointer;
   user-select: none;
}

.checkbox {
   display: flex;
   align-items: center;
   gap: 8px;
   text-wrap: nowrap;

   label {
      user-select: none;

      &:hover {
         cursor: pointer;
      }
   }
}

.bdd-block {
   display: block;
}