@use "./variables.scss";

@mixin flex-row {
   display: flex;
   gap: 24px;

   @media (width <=640px) {
      flex-direction: column;
   }
}

.row {
   @include flex-row;
}

.row-4 {
   @include flex-row;
   gap: 32px;
}

.no-wrap {
   white-space: nowrap;
}